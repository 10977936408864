<script setup lang="ts">
import logoCignaGray from '../../assets/carrier-cigna-gray.svg'
import logoHumanaGray from '../../assets/carrier-humana-gray.svg'
import logoBlueCrossGray from '../../assets/carrier-blue-cross-gray.svg'
import logoAnthemGray from '../../assets/carrier-anthem-gray.svg'
import logoAetnaGray from '../../assets/carrier-aetna-gray.svg'
import logoUnitedGray from '../../assets/carrier-united-gray.svg'

const carriers = [
  {
    id: 'cigna',
    image: logoCignaGray,
  },
  {
    id: 'humana',
    image: logoHumanaGray,
  },
  {
    id: 'blueCrossBlueShield',
    image: logoBlueCrossGray,
  },
  {
    id: 'anthem',
    image: logoAnthemGray,
  },
  {
    id: 'aetna',
    image: logoAetnaGray,
  },
  {
    id: 'unitedHealthcare',
    image: logoUnitedGray,
  },
]

useHead({
  title: `Additional benefits buyback 2024 - ${brand.name}`,
})

const isProd = import.meta.env.MODE === 'production'

const isMounted = useMounted()

const isPaidUser = ref(true)
const isMobile = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
  isMobile.value = window.innerWidth < 640
})

onMounted(() => {
  if (isPaidUser.value && isMobile.value) {
    enterExperiment('newBuyback2024Image')
  }

  if (
    brand.id === 'bh' &&
    isPaidUser.value &&
    isMobile.value &&
    query.utm_source?.toLowerCase() !== 'google'
  ) {
    enterExperiment('carrierLogosBelowTheForm')
  }
})

const showDefaultExperience = computed(() => {
  return !isMobile.value && import.meta.env.MODE === 'production'
})

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minGiveback && state.maxGiveback) {
    return {
      code: state.code,
      name: state.name,
      minGiveback: `$${Math.round(12 * state.minGiveback)}`,
      maxGiveback: `$${Math.round(12 * state.maxGiveback)}`,
    }
  }
})

const lowBid = ref(false)

provide('lowBid', lowBid)
</script>

<template>
  <Layout
    header="simple3"
    :hideHeader="isMobile"
    footer="buyback2024"
    class="container"
  >
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        <template v-if="showDefaultExperience">
          Are you shopping for Medicare Insurance plans? A licensed insurance
          agent can check your eligibility.
        </template>
        <template v-else-if="isPaidUser && isProd && isMobile && state">
          Some Americans with Medicare in {{ state.name }} are getting extra
          benefits like dental, vision, or
          <span class="text-red"> money back on their Social Security </span>
          with some Medicare Advantage Plans.
        </template>
        <template v-else>
          Have Medicare? Turning 65? Some Americans with Medicare A&B in
          {{ ip.lookup?.stateName || 'your area' }} are getting
          <span class="text-red">
            extra benefits like dental, vision, or Part B Premium Reduction
          </span>
          with some Medicare Advantage Plans.
        </template>
      </div>

      <template v-if="isMounted">
        <template v-if="showDefaultExperience">
          <img
            src="../../assets/helpline-seniors-hands-up.png"
            width="1254"
            height="836"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
        </template>
        <template v-else>
          <img
            v-if="exp?.newBuyback2024Image?.yoga"
            src="../../assets/buyback-yoga.jpg"
            width="1920"
            height="1080"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
          <img
            v-else-if="exp?.newBuyback2024Image?.iceCream"
            src="../../assets/buyback-ice-cream.jpg"
            width="1920"
            height="1080"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
          <img
            v-else-if="exp?.newBuyback2024Image?.happyWoman"
            src="../../assets/buyback-happy-woman.jpg"
            width="1920"
            height="1080"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
          <img
            v-else
            src="../../assets/buyback-2024.jpeg"
            width="1200"
            height="627"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
        </template>
      </template>

      <div
        class="-mx-4 [box-shadow:0_0_6px_rgba(0,0,0,0.3)] sm:rounded sm:mx-0 relative"
      >
        <UseForm v-slot="{ values }">
          <Form
            id="buyback2024"
            class="py-6 px-4 sm:px-6 !max-w-3xl"
            scrollBehavior="auto"
            :steps="[
              { id: 'medicareab' },
              { id: 'sorry', if: false },
              { id: 'medicaid' },
              { id: 'us', if: false },
              { id: 'moneyBack', if: isPaidUser && isMobile },
              { id: 'loading' },
              {
                id: 'call',
                if: isProd
                  ? isWorkingHours && isMobile && values?.haveMedicare !== false
                  : true,
              },
              {
                id: 'clicks',
                if: isProd
                  ? !(
                      isWorkingHours &&
                      isMobile &&
                      values?.haveMedicare !== false
                    )
                  : false,
              },
              { id: 'call-now', if: false },
            ]"
            product="medicare"
          />
        </UseForm>
      </div>

      <div
        v-if="exp?.carrierLogosBelowTheForm?.show"
        class="-mx-4 sm:mx-0 bg-gray-200 !mt-0"
      >
        <div class="container py-12">
          <div class="flex flex-wrap justify-center gap-4">
            <img
              v-for="carrier in carriers"
              :key="carrier.id"
              :src="carrier.image"
              class="w-24 h-8 object-contain"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["giveback"],
    "brands": ["bh", "med10", "m10", "boomer"],
    "smid": {
      "m10": "MULTI-PLAN_HLWEB0624201_M",
      "bh": "MULTI-PLAN_EHWEB062301_M",
      "boomer": "MULTI-PLAN_EHWEB082330_M",
      "med10": "MULTI-PLAN_EHWEB092333_M"
    },
    "robots": "noindex",
    "xsmid": {
      "m10": "MULTI-PLAN_HLWEB0624201_M",
      "bh": "MULTI-PLAN_EHWEB062301_M",
      "boomer": "MULTI-PLAN_EHWEB082330_M",
      "med10": "MULTI-PLAN_EHWEB092333_M"
    }
  }
}
</route>
