<script setup lang="ts">
import { Ref } from 'vue'

const { value: haveMedicaid } = useField('haveMedicaid')
const state = inject('state') as Ref<any>
</script>

<template>
  <div class="text-xl text-red text-center font-medium">
    <FormProgress class="!mb-6" />
  </div>

  <div class="text-xl text-center font-medium">
    Are you receiving benefits from {{ state?.name ?? 'America' }} Medicaid?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button wide size="sm" type="submit" @click="haveMedicaid = true">
      Yes
    </Button>
    <Button wide size="sm" type="submit" @click="haveMedicaid = false">
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="haveMedicaid = null"
    >
      Skip
    </Button>
  </div>
</template>
