<script setup lang="ts">
import buybackCouple from '../../assets/buyback-couple.png'
import blueEnvelope from '../../assets/envelope-advantage-card.png'

useHead({
  title: `Additional Benefits OTC - ${brand.name}`,
})

const state = computed(() => {
  const state = states.find((state) => state.code === ip.lookup?.stateCode)
  if (state && state.minOtc !== null && state.minOtcLtd) {
    return {
      code: state.code,
      name: state.name,
      minOtc: USDollarFormatter(0).format(state.minOtc),
      minOtcLtd: USDollarFormatter(0).format(state.minOtcLtd),
    }
  }
})

provide('state', state)
enterExperiment('additionalBenefitsOtc2024Image')

const isMobile = useIsMobile()
</script>

<template>
  <Layout header="simple3" footer="giveback" class="container">
    <template #banner>
      <Banner>
        On Medicare and Medicaid? *Available Medicare Advantage D-SNP
      </Banner>
    </template>

    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <h2
        :class="[
          !isMobile
            ? 'text-2xl text-center text-red font-medium font-dm'
            : 'text-xl sm:text-3xl font-bold text-center',
        ]"
      >
        Some Medicare members in {{ state?.name || 'America' }} may be
        <span class="text-red">
          eligible for {{ state?.minOtcLtd ?? '$500' }} or more
        </span>
        towards plan-approved OTC products with some
        <span class="text-red">Medicare Advantage Plans</span>.
      </h2>

      <img
        :src="isMobile ? blueEnvelope : buybackCouple"
        width="1024"
        height="1024"
        class="w-[90%] sm:w-[400px] mx-auto"
        alt="Happy Senior couple n a grocery store"
      />
      <div class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white">
        <Form
          id="otc2024"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'medicare' },
            { id: 'medicaid' },
            { id: 'otcallowance' },
            { id: 'call', completed: true },
          ]"
          product="medicare"
        />
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["otc"],
    "brands": ["bh", "med10", "m10", "boomer", "bridge"],
    "robots": "noindex",
    "smid": {
      "m10": "MULTI-PLAN_HLWEB0524174_M",
      "bh": "MULTI-PLAN_HLWEB1023108_M"
    }
  }
}
</route>
